import {
    CategoriesAction,
    LOAD_CATEGORIES_FAIL,
    LOAD_CATEGORIES_FINISH,
    LOAD_CATEGORIES_START,
} from '../actions/categoriesAction';
import {ISection} from '../types/types';

const initialStore: ISection = {
    categoryLoaded: false,
    categories: {},
    musicCategories: [],
    musicTops: [],
    error: false,
    authorized: false,
    user: null,
};

export function categoriesReducer(state: ISection = initialStore, action: CategoriesAction): ISection {
    switch (action.type) {
        case LOAD_CATEGORIES_START: {
            return {...state, categoryLoaded: false}
        }
        case LOAD_CATEGORIES_FINISH: {
            return {
                ...state,
                categoryLoaded: true,
                categories: {
                    ...state.categories,
                    [action.payload.lang]: action.payload.categories,
                },
                musicCategories: action.payload.musicCategories,
                musicTops: action.payload.musicTops,
                authorized: action.payload.authorized,
                user: action.payload.user,
            }
        }
        case LOAD_CATEGORIES_FAIL: {
            return {...state, categoryLoaded: false, error: action.error}
        }
    }
    return state;
}
