import React, {forwardRef, Fragment, Ref, useEffect, useState} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {LOAD_ACCESS_START} from '../../actions/accessAction';
import {unsubAccess} from '../../api';
import {
  ACCESS_URL,
  AUTH_LINK_EN,
  AUTH_LINK_RU,
  AUTH_LINK_SECONDARY_EN,
  AUTH_LINK_SECONDARY_RU,
  PROFILE_ID_SECONDARY,
} from '../../constants/constants';
import {msisdnMask} from '../../helpers/mask';
import {AccessAlias, IAppStore, IUserData, Languages} from '../../types/types';

import './UserPanel.css';

interface IUserPanelProps {
  className?: string;
}

const userPanel: ClassNameFormatter = cn('UserPanel');

interface IService {
  title: string;
  alias: AccessAlias;
  active: boolean;
  authLink: string;
  profileId: IUserData['profile_id'];
  loaded: boolean;
  unsubFetching: boolean;
  unsubError: string | null;
  selected: boolean;
}

const initServices: IService[] = [
  {
    title: 'MEN’S LIVE',
    alias: AccessAlias.PRIMARY,
    active: false,
    authLink: AUTH_LINK_RU,
    profileId: null,
    loaded: false,
    unsubFetching: false,
    unsubError: null,
    selected: false,
  },
  {
    title: 'BE WOMAN',
    alias: AccessAlias.SECONDARY,
    active: false,
    authLink: AUTH_LINK_SECONDARY_RU,
    profileId: null,
    loaded: false,
    unsubFetching: false,
    unsubError: null,
    selected: false,
  },
]

export const UserPanel = forwardRef(({ className }: IUserPanelProps, ref: Ref<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const {
    lang,
    user,
    access,
  } = useSelector((store: IAppStore) => ({
    lang: store.lang,
    user: store.section.user,
    access: store.access,
  }), shallowEqual);

  const [services, setServices] = useState<IService[]>(initServices);
  const selected = services.find((service) => service.selected) || null;

  const msisdn = user ? user.msisdn : null;

  useEffect(() => {
    setServices((prev) => prev.map((prevService) => {
      const alias = prevService.alias;
      const current = access[alias];
      const data = current.data;
      const authLink = (() => {
        switch (lang) {
            // case (Languages.tj): return alias === AccessAlias.PRIMARY ? AUTH_LINK_TJ : AUTH_LINK_SECONDARY_TJ
          case (Languages.en): return alias === AccessAlias.PRIMARY ? AUTH_LINK_EN : AUTH_LINK_SECONDARY_EN;
          default: return alias === AccessAlias.PRIMARY ? AUTH_LINK_RU : AUTH_LINK_SECONDARY_RU;
        }
      })();
      const profileId = (() => {
        switch (alias) {
          case AccessAlias.PRIMARY: return user ? user.profile_id : null;
          case AccessAlias.SECONDARY: return PROFILE_ID_SECONDARY;
          default: return null;
        }
      })();
      const active = data ? data.is_active : false

      return {
        ...prevService,
        authLink,
        profileId,
        active,
        loaded: current.loaded,
        unsubError: !active ? null : prevService.unsubError,
      }
    }));
  }, [lang, user, access]);

  const setSelected = (service: IService | null) => {
    setServices((prev) => prev.map((prevService) => ({
      ...prevService,
      selected: service ? (prevService.alias === service.alias) : false,
    })));
  };
  const closeConfirm = () => setSelected(null);
  const subscribe = (service: IService) => {
    document.location.href = service.authLink;
  };
  const unsubscribe = (service: IService) => {
    closeConfirm();
    const setUnsubFetching = (unsubFetching: boolean) => {
      setServices((prev) => prev.map((prevService) => {
        const isCurrent = prevService.alias === service.alias;
        if (isCurrent) {
          return {
            ...prevService,
            unsubFetching,
          }
        } else {
          return prevService
        }
      }));
    };
    setUnsubFetching(true);
    unsubAccess(`${ACCESS_URL}/unsubscribe?msisdn=${msisdn}&profile_id=${service.profileId}`)
        .then((res) => {
          if (res.text === 'Success') {
            dispatch({
              type: LOAD_ACCESS_START,
              payload: { alias: service.alias, msisdn, profileId: service.profileId },
            })
          } else {
            setServices((prev) => prev.map((prevService) => {
              const isCurrent = prevService.alias === service.alias;
              if (isCurrent) {
                return {
                  ...prevService,
                  unsubError: `Ошибка при отключении сервиса "${prevService.title}". Повторите попытку позже`,
                }
              } else {
                return prevService
              }
            }));
          }
        })
        .catch()
        .finally(() => {
          setUnsubFetching(false)
        })
  }
  const submitConfirm = (selectedActive: boolean) => {
    if (selected) {
      if (selectedActive) {
        unsubscribe(selected);
      }
    }
  };
  const click = (service: IService) => {
    if (selected && (selected.alias === service.alias)) {
      closeConfirm()
    } else {
      setServices((prev) => prev.map((prevService) => ({
        ...prevService,
        unsubError: null,
      })));
      if (service.active) {
        setSelected(service);
      } else {
        subscribe(service)
      }
    }
  };

  const confirmEl = selected ? (
    <div className={userPanel('Confirm')}>
      <div className={userPanel('ConfirmTitle')}>
        {selected.active
          ? `Вы уверены что хотите отключить сервис "${selected.title}"?`
          : `Вы уверены что хотите подключить сервис "${selected.title}"?`}
      </div>
      <div className={userPanel('ConfirmButtons', { active: selected.active })}>
        <button
          className={userPanel('ConfirmButton', { active: !selected.active })}
          type={'button'}
          onClick={(ev) => {
            ev.stopPropagation();
            submitConfirm(selected.active);
          }}
        >
          Да
        </button>
        <button
          className={userPanel('ConfirmButton', { active: selected.active })}
          type={'button'}
          onClick={(ev) => {
            ev.stopPropagation()
            closeConfirm()
          }}
        >
          Нет
        </button>
      </div>
    </div>
  ) : null;

  if (user) {
    return (
      <div ref={ref} className={classnames(userPanel('Container'), className)}>
        <div className={userPanel('Field')}>
          <div className={userPanel('FieldLabel')}>Ваш телефон</div>
          <div className={userPanel('FieldInput')}>{msisdnMask(msisdn)}</div>
        </div>
          <div className={userPanel('Services')}>
            {services.map((service) => {
              const showConfirm = selected && (service.alias === selected.alias);
              const disabled = !service.loaded || service.unsubFetching

              return (
                <Fragment key={service.alias}>
                  <button
                    className={userPanel('Service', { active: service.active, disabled })}
                    onClick={!disabled ? () => click(service) : undefined}
                    type={'button'}
                  >
                    <div className={userPanel('ServiceCheckbox')}/>
                    <div className={userPanel('ServiceLabel')}>{service.title}</div>
                  </button>
                  {service.unsubError ? (
                      <div className={userPanel('ServiceError')}>{service.unsubError}</div>
                  ) : null}
                  {showConfirm ? confirmEl : null}
                </Fragment>
              );
            })}
          </div>
      </div>
    );
  }
  return null;
});
