import {combineReducers, Reducer} from 'redux';

import {CombinedAction} from '../actions';
import {IAppStore} from '../types/types';

import {accessReducer} from './accessReducer';
import {categoriesReducer} from './categoriesReducer';
import {contentReducer} from './contnetReducer';
import {footerReducer} from './footerReducer';
import {languageReducer} from './languageReducer';
import {offerReducer} from './offerReducer';
import {playerReducer} from './playerReducer';
import {manifestReducer} from './pressContentReduser';
import {subscriptionReducer} from './subscriptionReducer';

export const rootReducer: Reducer<IAppStore, CombinedAction> = combineReducers({
    section: categoriesReducer,
    playerInfo: playerReducer,
    pressContent: manifestReducer,
    content: contentReducer,
    offerText: offerReducer,
    footerText: footerReducer,
    subscriptionText: subscriptionReducer,
    lang: languageReducer,
    access: accessReducer,
});
