import {AccessAlias, IAccessData, IUserData} from '../types/types';

export const LOAD_ACCESS_START = 'LOAD_ACCESS_START';
export const LOAD_ACCESS_FINISH = 'LOAD_ACCESS_FINISH';
export const LOAD_ACCESS_FAIL = 'LOAD_ACCESS_FAIL';

export type LOAD_ACCESS_START_TYPE = typeof LOAD_ACCESS_START;
export type LOAD_ACCESS_FINISH_TYPE = typeof LOAD_ACCESS_FINISH;
export type LOAD_ACCESS_FAIL_TYPE = typeof LOAD_ACCESS_FAIL;

export interface ILoadAccessStartPayload {
    alias: AccessAlias
    msisdn: IUserData['msisdn']
    profileId: IUserData['profile_id']
}

export interface ILoadAccessStartAction {
    type: LOAD_ACCESS_START_TYPE;
    payload: ILoadAccessStartPayload;
}

export interface ILoadAccessFinishPayload {
    alias: AccessAlias
    data: IAccessData
}

export interface ILoadAccessFinishAction {
    type: LOAD_ACCESS_FINISH_TYPE;
    payload: ILoadAccessFinishPayload;
}

interface ILoadAccessFailPayload {
    alias: AccessAlias
    error: boolean
}

export interface ILoadAccessFailAction {
    type: LOAD_ACCESS_FAIL_TYPE;
    payload: ILoadAccessFailPayload
}

export type AccessAction = ILoadAccessStartAction | ILoadAccessFinishAction | ILoadAccessFailAction;
