import {
    AccessAction,
    LOAD_ACCESS_FAIL,
    LOAD_ACCESS_FINISH,
    LOAD_ACCESS_START,
} from '../actions/accessAction';
import {AccessAlias, IAccessSection, IAccessSectionItem} from '../types/types';

const initialItem: IAccessSectionItem = {
    data: null,
    loaded: false,
    error: false,
}
const initialStore: IAccessSection = {
    [AccessAlias.PRIMARY]: initialItem,
    [AccessAlias.SECONDARY]: initialItem,
};

export function accessReducer(state: IAccessSection = initialStore, action: AccessAction): IAccessSection {
    switch (action.type) {
        case LOAD_ACCESS_START: {
            return {
                ...state,
                [action.payload.alias]: {
                    data: state[action.payload.alias].data,
                    loaded: false,
                    error: false,
                },
            }
        }
        case LOAD_ACCESS_FINISH: {
            return {
                ...state,
                [action.payload.alias]: {
                    data: action.payload.data,
                    loaded: true,
                    error: false,
                },
            }
        }
        case LOAD_ACCESS_FAIL: {
            return {
                ...state,
                [action.payload.alias]: {
                    data: null,
                    loaded: false,
                    error: true,
                },
            }
        }
    }
    return state;
}
