export const msisdnMask = (msisdn: number | null) => {
  if (msisdn) {
    const msisdnArray = `${msisdn}`.match(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/);
    if (Array.isArray(msisdnArray)) {
      const numberCountry = msisdnArray[1] || '***';
      const numberOperator = msisdnArray[2] || '**';
      const numberOne = msisdnArray[3] || '***';
      const numberTwo = msisdnArray[4] || '**';
      const numberThree = msisdnArray[5] || '**';
      return `+${numberCountry} (${numberOperator}) ${numberOne}-${numberTwo}-${numberThree}`;
    }
    return msisdn;
  }
  return '+*** (**) ***-**-**';
};
